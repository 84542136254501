import React from 'react';
import FormLogin from '../components/login/FormLogin';

const Login = () => {
  return (
    <>
      {/*  <Header titulo="Login" /> */}
      <FormLogin />
    </>
  );
}

export default Login;
